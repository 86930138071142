@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
#SideBar {
    background-color: rgb(25, 38, 62);
}

.side-menu {
    height: calc(100vh - 130px);
    overflow-y: scroll;
}
html {
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
    font-size: 100%;
}
*, *:before, *:after {
    box-sizing: inherit;
    margin:0;
    padding:0;
}
.mid {
    display: flex;
    align-items: center;
    justify-content: center;
    /*padding-top:1em;*/
}


/* Switch starts here */
.rocker {
    display: inline-block;
    position: relative;
    /*
    SIZE OF SWITCH
    ==============
    All sizes are in em - therefore
    changing the font-size here
    will change the size of the switch.
    See .rocker-small below as example.
    */
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: #888;
    width: 7em;
    height: 4em;
    overflow: hidden;
    border-bottom: 0.5em solid #eee;
}

.rocker-small {
    font-size: 0.75em; /* Sizes the switch */
    /*margin: 1em;*/
}

.rocker::before {
    content: "";
    position: absolute;
    top: 0.5em;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #999;
    border: 0.5em solid #eee;
    border-bottom: 0;
}

.rocker input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch-left, .switch-right {
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5em;
    width: 3em;
    transition: 0.2s;
}

.switch-left {
    height: 2.4em;
    width: 2.75em;
    left: 0.85em;
    bottom: 0.4em;
    background-color: #ddd;
    -webkit-transform: rotate(15deg) skewX(15deg);
            transform: rotate(15deg) skewX(15deg);
}

.switch-right {
    right: 0.5em;
    bottom: 0;
    background-color: #bd5757;
    color: #fff;
}

.switch-left::before, .switch-right::before {
    content: "";
    position: absolute;
    width: 0.4em;
    height: 2.45em;
    bottom: -0.45em;
    background-color: #ccc;
    -webkit-transform: skewY(-65deg);
            transform: skewY(-65deg);
}

.switch-left::before {
    left: -0.4em;
}

.switch-right::before {
    right: -0.375em;
    background-color: transparent;
    -webkit-transform: skewY(65deg);
            transform: skewY(65deg);
}

input:checked + .switch-left {
    background-color: #0084d0;
    color: #fff;
    bottom: 0px;
    left: 0.5em;
    height: 2.5em;
    width: 3em;
    -webkit-transform: rotate(0deg) skewX(0deg);
            transform: rotate(0deg) skewX(0deg);
}

input:checked + .switch-left::before {
    background-color: transparent;
    width: 3.0833em;
}

input:checked + .switch-left + .switch-right {
    background-color: #ddd;
    color: #888;
    bottom: 0.4em;
    right: 0.8em;
    height: 2.4em;
    width: 2.75em;
    -webkit-transform: rotate(-15deg) skewX(-15deg);
            transform: rotate(-15deg) skewX(-15deg);
}

input:checked + .switch-left + .switch-right::before {
    background-color: #ccc;
}

/* Keyboard Users */
input:focus + .switch-left {
    color: #333;
}

input:checked:focus + .switch-left {
    color: #fff;
}

input:focus + .switch-left + .switch-right {
    color: #fff;
}

input:checked:focus + .switch-left + .switch-right {
    color: #333;
}
#TopBar {
    background-color: rgb(18, 30, 52);
    padding: 12px;
}

#TopBar, a {
    color: white;
}
.App {
    display: flex;
    width: 100%;
    text-align: center;
    align-items: stretch;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
  
body {
    font-family: "Poppins", sans-serif;
    background: #fafafa;
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
    min-width: 250px;
    max-width: 250px;
    background: rgb(25, 38, 62);
    color: #fff;
    margin-left: -250px;
    transition: all 0.5s;
}

.sidebar.is-open {
    margin-left: 0;
    transition: 0.5s;
}

.sidebar-header {
    background: #6d7fcc;
}

.sidebar-header h3 {
    color: #fff;
    padding: 1em;
}

.sidebar ul p {
    color: #fff;
    padding: 10px;
}

.menu-open {
    background: #6d7fcc;
}

.nav-item:hover {
    color: #7386d5;
    background: #fff;
}

.items-menu {
    color: #fff;
    background: #6d7fcc;
}

li a.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    left: 60%;
    top: 10%;
}

.sidebar-header > span {
    position: relative;
    float: right;
    margin: 0.5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
}
.side-menu {
    height: calc(100vh - 130px);
    overflow-y: scroll;
}

.side-menu::-webkit-scrollbar {
    width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
    background: #5466b3;
    border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
    background: #3a4c99;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
    padding: 20px;
    margin-left: 0;
    height: 100vh;
}

@media only screen and (max-width: 500px) {
    body {
        overflow: hidden;
    }

    .content.is-open {
        margin-left: 100%;
    }

    .sidebar.is-open {
        min-width: 100%;
        max-width: 100%;
        margin-left: 0;
        transition: all 0.5s, height 0s;
    }

    .sidebar.is-open > .sidebar-header span {
        display: unset;
    }

    li a.dropdown-toggle::after {
        display: inline-block;
        position: relative;
        left: 68%;
    }
}

#authApp {
    text-align: left;
    padding: 1em;
    margin: 1em;
    border: 2px solid #d3d3d3;
    border-radius: .5em;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    margin-top: 100px;
}
  
#authApp .form {
    padding: 1em;
}
  
#authApp label {
    display: flex;
    font-weight: 600;
}
  
#authApp button {
    justify-content: flex-end;
}
  
#authApp .App-title {
    font-size: 1.5em;
}
  
#authApp .App-intro {
    font-size: large;
}
  
@-webkit-keyframes App-logo-spin {
    from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
  
@keyframes App-logo-spin {
    from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

